var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "table-cell-log-action" }, [
    _c(
      "span",
      {
        directives: [
          {
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top",
            value: { customClass: "custom-tooltip" },
            expression: "{customClass: 'custom-tooltip' }",
            modifiers: { hover: true, top: true },
          },
        ],
        attrs: { title: _setup.tooltip },
      },
      [_vm._v(_vm._s(_setup.action))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }