import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { faEdit } from '@icons/solid/faEdit';
import { faArrowUp } from '@icons/solid/faArrowUp';
import { faArrowDown } from '@icons/solid/faArrowDown';

import * as models from '@models';
import { modals } from '@modals';
import { alert } from '@services/alert';
import { store } from '@store';

const columns: TablePanel.Column<models.Module>[] = [
  {
    key: 'id',
    label: 'ID',
    value: 'id',
    type: 'number',
  },
  {
    key: 'name',
    label: 'Name',
    value: 'name',
  },
  { key: 'possibleScore', label: 'Possible Score', value: 'possibleScore' },
  {
    key: 'scenes',
    label: 'Scenes',
    value: ({ scenes }) => (scenes || []).map((scene) => scene.name).join('\n'),
  },
];

const operations: TablePanel.Row.Operation<models.Module>[] = [
  {
    label: 'Edit',
    icon: faEdit,
    fn: ({ id }) => {
      void modals.module.edit({ moduleId: id });
    },
  },
  {
    label: 'Set to Active',
    icon: faArrowUp,
    fn: ({ id }) => {
      void setModuleState(id, true);
    },
    hidden: ({ active }) => active,
  },
  {
    label: 'Set to Inactive',
    icon: faArrowDown,
    fn: ({ id }) => {
      void setModuleState(id, false);
    },
    hidden: ({ active }) => !active,
  },
];

@Module({ namespaced: true })
export class ModuleTablePanel
  extends TablePanel<models.Module>
  implements TablePanel.Props<models.Module>
{
  readonly module = 'modules';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'MODULES';
  readonly columns = columns;
  readonly operations = operations;
  readonly createAction = 'createModule';
  readonly deleteAction = 'deleteModule';
  readonly enabled = true;
  readonly tableSortType = 'id';

  @Action
  async createModule() {
    await modals.module.create();
  }

  @Action
  async deleteModule({ items }: ModuleTablePanel.DeleteItemOptions) {
    await modals.confirm.deleteItems({ itemType: 'modules', items });
  }
}

export namespace ModuleTablePanel {
  /** ... */
  export interface DeleteItemOptions {
    items: models.Module[];
  }
}

//#region Helper Functions

/**
 * ...
 *
 * @param moduleId ...
 * @param active ...
 */
async function setModuleState(moduleId: models.Module['id'], active: boolean) {
  try {
    await store.dispatch('modules/update', { moduleId, active });
  } catch (err) {
    return alert.error(err);
  }

  if (active) {
    alert.success('Module set to "Active".');
  } else {
    alert.success('Module set to "Inactive".');
  }
}

//#endregion Helper Functions
