import { Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { auth, RoleId } from '@auth';
import { modals } from '@modals';
import { User, Role } from '@models';
import { router } from '@router';
import { printTable } from '@services/print-table';
import { alert } from '@services/alert';
import { store } from '@store';
// import { aggregateReports } from '@tools/simulator';
import { ensureError } from '@tools/ensure-error';

import { faBook } from '@icons/solid/faBook';
import { faClipboardListCheck } from '@icons/solid/faClipboardListCheck';
import { faBox } from '@icons/solid/faBox';
import { faIdCardAlt } from '@icons/solid/faIdCardAlt';
import { faAddressCard } from '@icons/solid/faAddressCard';
import { faTrashCan } from '@icons/solid/faTrashCan';
import { faPaperPlane } from '@icons/solid/faPaperPlane';
import { faDiploma } from '@fortawesome/pro-solid-svg-icons/faDiploma';
import { faUserPlus } from '@icons/solid/faUserPlus';
import { faBan } from '@icons/solid/faBan';
import { faUserSlash } from '@icons/solid/faUserSlash';

const columns: TablePanel.Column<User>[] = [
  {
    key: 'active',
    label: 'Status',
    value: 'active',
    component: 'TableCellUserStatus',
    hidden: ({ selectedRole }) => selectedRole?.roleId !== RoleId.LasAdmin,
  },
  {
    key: 'name',
    label: 'Name',
    value: ({ firstName, lastName }) => {
      if (firstName && !lastName) return firstName;
      if (!firstName && lastName) return lastName;
      if (firstName && lastName) return `${firstName} ${lastName}`;
      return 'No Name';
    },
    filterByFormatted: true,
    sortByFormatted: true,
  },
  {
    key: 'email',
    label: 'Email',
    value: 'email',
    component: 'TableCellUserEmail',
  },
  {
    key: 'roles',
    label: 'Roles',
    value: 'roles',
    component: 'TableCellUserRoles',
  },
  // {
  //   key: 'totalFlightTime',
  //   label: 'Total Flight Time (hh:mm:ss)',
  //   value: ({ id }) => {
  //     return getMetricValue(id, 'totalFlightTime', secToHmsFilter);
  //   },
  // },
  // {
  //   key: 'reportCount',
  //   label: 'Total Recent Reports (90 days)',
  //   value: ({ id }) => {
  //     return getMetricValue(id, 'reportCount');
  //   },
  // },
  // {
  //   key: 'active',
  //   label: 'Active',
  //   type: 'boolean',
  //   value: 'active',
  // },
];

const operations: TablePanel.Row.Operation<User>[] = [
  // {
  //   label: 'Upgrade Student',
  //   icon: faLevelUp,
  //   fn: (user) => {
  //     // modals.user.edit({ user });
  //   },
  //   hidden: ({ studentUpgradable }) => !studentUpgradable,
  // },
  {
    label: 'Manage',
    icon: faAddressCard,
    fn: (user) => {
      void modals.user.details({ user });
    },
    hidden() {
      return !auth.isActiveRole(RoleId.LasAdmin);
    },
  },
  {
    label: 'View Details',
    icon: faAddressCard,
    fn: (user) => {
      void modals.user.details({ user });
    },
    hidden() {
      return auth.isActiveRole(RoleId.LasAdmin);
    },
  },
  {
    label: 'View Courses',
    icon: faBook,
    fn: ({ id: userId }) => {
      void modals.user.courses({ userId });
    },
  },
  {
    label: 'View Orders',
    icon: faBox,
    fn: ({ id: userId }) => {
      void modals.user.orders({ userId });
    },
    hidden: () => {
      return !auth.isActiveRole(RoleId.LasAdmin);
    },
  },
  {
    label: 'View Roles',
    icon: faIdCardAlt,
    fn: ({ id: userId }) => {
      void modals.user.roles({ userId });
    },
    hidden: () => {
      return !auth.isActiveRole(
        RoleId.Instructor,
        RoleId.InstitutionAdmin,
        RoleId.LasAdmin,
      );
    },
  },
  {
    label: 'Go To Reports',
    icon: faClipboardListCheck,
    fn: (user) => {
      void router.push(`/dashboard/reports/${user.id}`);
      // void modals.user.reports({ user });
    },
    hidden: () => {
      return !auth.isActiveRole(
        RoleId.Instructor,
        RoleId.InstitutionAdmin,
        RoleId.LasAdmin,
      );
    },
  },
  {
    label: 'Invite Admin/Instructor',
    icon: faPaperPlane,
    fn: (item) => {
      if (auth.isActiveRole(RoleId.InstitutionAdmin)) {
        void modals.user.invite({
          organizationName: auth.activeRole?.organization?.name as string,
          targetOrganizationId: auth.activeRole?.organization?.id as string,
          targetEmails: [item.email],
        });
      }
    },
    hidden: ({ roles }) => {
      if (auth.isActiveRole(RoleId.InstitutionAdmin)) {
        if (roles?.length === 0) return false;
        if (roles?.some((r) => r.roleId === RoleId.Student)) return false;
      }
      return true;
    },
  },
  {
    label: 'Edit Certification Permissions',
    icon: faDiploma,
    fn: (item) => {
      void modals.certifications.editCertificationPermissions({
        roles: item.roles as Role[],
        userId: item.id,
      });
    },
    hidden: ({ roles }) => {
      if (
        roles?.filter(({ roleId }) => roleId === RoleId.Instructor).length === 0
      ) {
        return true;
      }

      if (auth.isActiveRole(RoleId.LasAdmin)) {
        return false;
      }

      if (
        auth.isActiveRole(RoleId.InstitutionAdmin) &&
        !!auth.activeRole?.certifications?.length
      ) {
        return false;
      }

      return true;
    },
  },
  {
    label: 'Add Course',
    icon: faUserPlus,
    fn: (item) => {
      void modals.user.addCourse({
        targetOrganizationId: auth.activeRole?.organization?.id as string,
        targetOrganizationName: auth.activeRole?.organization?.name as string,
        user: item,
      });
    },
    hidden: ({ roles }) => {
      if (auth.isActiveRole(RoleId.InstitutionAdmin)) {
        if (roles?.length === 0) return false;
        if (roles?.some((r) => r.roleId === RoleId.Student)) return false;
      }

      return true;
    },
  },
  {
    label: 'Remove User',
    variant: 'danger',
    icon: faUserSlash,
    fn: async (item) => {
      const result = await modals.user.removeUserFromInstitution({
        user: item,
      });

      if (result.userRemoved) {
        // remove user from the row
      }
    },
    hidden: ({ roles }) => {
      if (auth.isActiveRole(RoleId.LasAdmin)) {
        return false;
      }

      if (auth.isActiveRole(RoleId.InstitutionAdmin)) {
        if (roles?.length === 0) {
          return false;
        }

        if (roles?.some((r) => r.roleId !== RoleId.InstitutionAdmin)) {
          return false;
        }
      }

      return true;
    },
  },
  {
    label: 'BLOCK',
    icon: faBan,
    variant: 'danger',
    fn: (user) => {
      let error: unknown = null;

      try {
        void store.dispatch('users/update', {
          userId: user.id,
          active: false,
        });
      } catch (err) {
        error = ensureError(err);
      }

      if (error) {
        return alert.error(error);
      }

      alert.success(`User ${user.email} was blocked`);
    },
    hidden: (item) => {
      if (!auth.isActiveRole(RoleId.LasAdmin)) return true;
      else if (item.active) return false;
      else return true;
    },
  },
  {
    label: 'UNBLOCK',
    icon: faBan,
    fn: (user) => {
      let error: unknown = null;

      try {
        void store.dispatch('users/update', {
          userId: user.id,
          active: true,
        });
      } catch (err) {
        error = ensureError(err);
      }

      if (error) {
        return alert.error(error);
      }

      alert.success(`User ${user.email} was unblocked`);
    },
    hidden: (item) => {
      if (!auth.isActiveRole(RoleId.LasAdmin)) return true;
      else if (!item.active) return false;
      else return true;
    },
  },
  {
    label: 'DELETE',
    variant: 'danger',
    icon: faTrashCan,
    fn: (user) => {
      // void modals.user.deleteUser({ user });

      void modals.confirm.deleteUsers({ users: [user] });
    },
    hidden: ({ deletedAt }) => {
      return !auth.isActiveRole(RoleId.LasAdmin) || !!deletedAt;
    },
  },
];

/** ... */
const filterFields = ['name', 'email'];

@Module({ namespaced: true })
export class UserTablePanel
  extends TablePanel<User>
  implements TablePanel.Props<User>
{
  readonly module = 'users';
  readonly loadAction = 'list';
  readonly loadPageAction = 'loadPage';
  readonly pk = 'id';
  readonly label = 'USERS';
  readonly columns = columns;
  readonly operations = operations;
  readonly filterFields = filterFields;

  get enabled() {
    return this.isActiveRole(
      RoleId.Instructor,
      RoleId.InstitutionAdmin,
      RoleId.Reseller,
      RoleId.LasAdmin,
    );
  }

  readonly progressive = true;

  /** ... */
  get tableMenu() {
    const roleId = this.activeUser.selectedRole?.roleId;

    const items: TablePanel.MenuItem[] = [];

    if (roleId === RoleId.Reseller || roleId === RoleId.LasAdmin) {
      items.push({
        key: 'InviteIndependentOperator',
        label: 'Invite Independent Operator(s)',
        click: () => void modals.user.inviteIndieOperator(),
      });
    }

    if (
      roleId === RoleId.Instructor ||
      roleId === RoleId.InstitutionAdmin ||
      roleId === RoleId.LasAdmin
    ) {
      items.push({
        key: 'inviteStudent',
        label: 'Invite Student(s)',
        click: () => void modals.user.inviteStudent(),
      });
    }

    if (
      roleId === RoleId.InstitutionAdmin ||
      roleId === RoleId.Reseller ||
      roleId === RoleId.LasAdmin
    ) {
      items.push({
        key: 'inviteAdminOrInstructor',
        label: 'Invite Admin/Instructor(s)',
        click: () => void modals.user.invite(),
      });
    }

    if (roleId === RoleId.LasAdmin) {
      // items.push({
      //   key: 'InvliteResellerUsers',
      //   label: 'Invite Reseller Users',
      //   click: () => void modals.user.inviteReseller(),
      // });

      items.push({
        key: 'InviteReviewerUsers',
        label: 'Invite Reviewer(s)',
        click: () => void modals.user.inviteReviewers(),
      });

      items.push({
        key: 'InviteLasAdmin',
        label: 'Invite LAS Admin(s)',
        click: () => void modals.user.inviteLasAdmin(),
      });
    }

    if (
      roleId === RoleId.Instructor ||
      roleId === RoleId.InstitutionAdmin ||
      roleId === RoleId.LasAdmin
    ) {
      items.push({ type: 'separator' });

      items.push({
        key: 'loadReports',
        label: 'Load Reports',
        disabled: this.loadingMetrics,
        click: () => void store.dispatch('users/computeMetrics'),
      });
    }

    if (items.length) {
      items.push({ type: 'separator' });
    }

    items.push({
      key: 'print',
      label: 'Print',
      disabled: this.loadingMetrics,
      click: () => printTable.fromTableData(this.columns, this.rows),
    });

    return items;
  }

  /**
   * NOTE: This is intended to be used for institution admins ONLY until
   * the institution admins user table is paginated. Remove once properly
   * implemented.
   */
  get onPageChanged() {
    return (items: User[]) => {
      if (!this.isActiveRole(RoleId.Instructor, RoleId.InstitutionAdmin)) {
        // eslint-disable-next-line no-console
        return console.warn(
          'The "users/getDetails" action is intended for instructors and institution admins only. No data will be fetched.',
        );
      }

      // ...
      // const metrics = store.state.users.metrics ?? {};

      // ...
      const userIds: User['id'][] = [];

      for (const { id } of items) {
        // if (!metrics[id]) userIds.push(id);
        userIds.push(id);
      }

      if (!userIds.length) return;

      // REIMPLEMENT WHEN REPORT STATS ARE REDESIGNED
      // void store.dispatch('users/computeMetrics', { userIds });

      const organizationId = this.activeUser.selectedRole?.organization?.id;

      void store.dispatch('users/getDetails', {
        users: userIds,
        organizationId,
        includeLicenses: false,
        includeRoles: true,
      });
    };
  }

  /** ... */
  private get loadingMetrics() {
    return this.context.rootState.users.loadingMetrics;
  }
}

//#region Helper Functions

// /** ... */
// type MetricValueTransformer<T extends keyof aggregateReports.Results> = (
//   value: aggregateReports.Results[T],
//   metrics: aggregateReports.Results,
// ) => unknown;

// /**
//  * ...
//  *
//  * @param userId ...
//  * @param key ...
//  * @param transformer ...
//  * @return ...
//  */
// function getMetricValue<T extends keyof aggregateReports.Results>(
//   userId: User['id'],
//   key: T,
//   transformer?: MetricValueTransformer<T>,
// ) {
//   const metrics = store.state.users.metrics?.[userId];

//   if (!metrics) return null;

//   const value = metrics[key];

//   return transformer ? transformer(value, metrics) : value;
// }

//#endregion Helper Functions
