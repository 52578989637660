var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.top",
          value: { customClass: "custom-tooltip" },
          expression: "{customClass: 'custom-tooltip' }",
          modifiers: { hover: true, top: true },
        },
      ],
      staticClass: "table-cell-log-operating-system",
      attrs: { title: _setup.tooltip },
    },
    [
      _setup.os?.name === "Windows"
        ? _c("span", [_c("Icon", { attrs: { icon: _setup.faWindows } })], 1)
        : _setup.os?.name === "macOS"
        ? _c("span", [_c("Icon", { attrs: { icon: _setup.faApple } })], 1)
        : _setup.os?.name === "Linux"
        ? _c("span", [_c("Icon", { attrs: { icon: _setup.faLinux } })], 1)
        : _setup.os?.name === "Chrome"
        ? _c("span", [_c("Icon", { attrs: { icon: _setup.faChrome } })], 1)
        : _setup.os?.name === "iOS"
        ? _c("span", [_c("Icon", { attrs: { icon: _setup.faApple } })], 1)
        : _setup.os?.name === "Android"
        ? _c("span", [_c("Icon", { attrs: { icon: _setup.faAndroid } })], 1)
        : _c("span", [_c("Icon", { attrs: { icon: _setup.faQuestion } })], 1),
      _vm._v(" "),
      _c("span", { staticClass: "ml-2" }, [
        _vm._v(" " + _vm._s(_setup.os?.name)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }