var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _vm._v("\n  " + _vm._s(_setup.organizationName) + "\n  "),
    _setup.isTrial
      ? _c("span", { staticClass: "trial-badge" }, [_vm._v("Trial")])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }