<script setup lang="ts">
import { computed } from 'vue';
import type { OutputLogEvent } from '@models';

import { faChrome } from '@icons/brands/faChrome';
import { faSafari } from '@icons/brands/faSafari';
import { faFirefoxBrowser } from '@icons/brands/faFirefoxBrowser';
import { faOpera } from '@icons/brands/faOpera';
import { faEdge } from '@icons/brands/faEdge';
import { faBrave } from '@icons/brands/faBrave';
import { faAtomSimple } from '@icons/solid/faAtomSimple';
import { faUnity } from '@icons/brands/faUnity';
/**
 * `TableCellLogDevice` component properties.
 */
export interface Props {
  item: OutputLogEvent;
}

const props = defineProps<Props>();

const browser = computed(() => props.item.browser);
const cpu = computed(() => props.item.cpu);
const engine = computed(() => props.item.engine);

const tooltip = computed(() => {
  return `${cpu.value ? cpu.value.architecture : ''} ${
    browser.value ? `${browser.value.name} ${browser.value.major}` : ''
  } ${engine.value ? `${engine.value.name} ${engine.value.version}` : ''}`;
});
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellLogDevice: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellLogDevice' };
</script>

<template>
  <div
    class="table-cell-device"
    v-b-tooltip.hover.top="{ customClass: 'tooltip' }"
    :title="tooltip"
  >
    <span v-if="browser?.name === 'Chrome'">
      <Icon :icon="faChrome" />
    </span>
    <span v-else-if="browser?.name === 'Safari'">
      <Icon :icon="faSafari" />
    </span>
    <span v-else-if="browser?.name === 'Firefox'">
      <Icon :icon="faFirefoxBrowser" />
    </span>
    <span v-else-if="browser?.name === 'Opera' || browser?.name === 'Opera GX'">
      <Icon :icon="faOpera" />
    </span>
    <span v-else-if="browser?.name === 'Edge'">
      <Icon :icon="faEdge" />
    </span>
    <span v-else-if="browser?.name === 'Brave'">
      <Icon :icon="faBrave" />
    </span>
    <span v-else-if="browser?.name === 'Electron'">
      <Icon :icon="faAtomSimple" />
    </span>
    <span v-else-if="browser?.name === 'Unity'">
      <Icon :icon="faUnity" />
    </span>

    <span class="ml-2"> {{ browser?.name }}</span>
  </div>
</template>

<style scoped lang="scss">
.tooltip {
  max-width: none !important;
  width: auto !important;
  white-space: nowrap !important;
  font-size: 12px;
  padding: 10px;
}
:deep(.tooltip-inner) {
  display: inline-block !important;
  width: auto !important;
  max-width: none !important;
  background-color: rgba(0, 0, 0, 0.85) !important;
  padding: 20px 20px !important;
}
.table-cell-device {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
