// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.trial-badge[data-v-06c45810] {
  background-color: #da9f29;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  margin-left: 4px;
} 
`, "",{"version":3,"sources":["webpack://./client/components/TableCells/TableCellOrganizationName.vue"],"names":[],"mappings":";AA8CA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;EACA,gBAAA;AACA","sourcesContent":["<template>\n  <div>\n    {{ organizationName }}\n    <span v-if=\"isTrial\" class=\"trial-badge\">Trial</span>\n  </div>\n</template>\n\n<script setup lang=\"ts\">\nimport { computed } from 'vue';\n\nimport { Organization } from '@models';\n\n/**\n * `TableCellOrganizationName` component properties.\n */\nexport interface Props {\n  /** ... */\n  item: Organization;\n}\n\nconst props = defineProps<Props>();\n\nconst organizationName = computed(() => {\n  const { item } = props;\n\n  return item.name;\n});\n\nconst isTrial = computed(() => {\n  const { item } = props;\n\n  return item.isTrial;\n});\n</script>\n\n<script lang=\"ts\">\ndeclare module '@vue/runtime-core' {\n  export interface GlobalComponents {\n    TableCellOrganizationName: ComponentWithProps<Props>;\n  }\n}\n\nexport default { name: 'TableCellOrganizationName' };\n</script>\n\n<style scoped>\n.trial-badge {\n  background-color: #da9f29;\n  padding: 2px 4px;\n  border-radius: 4px;\n  font-size: 12px;\n  font-weight: bold;\n  color: #000;\n  margin-left: 4px;\n} \n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
