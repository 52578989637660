<script setup lang="ts">
import { computed } from 'vue';
import type { OutputLogEvent } from '@models';
import CountryFlag from '@components/CountryFlag.vue';
/**
 * `TableCellLogGeoIp` component properties.
 */
export interface Props {
  item: OutputLogEvent;
}

const props = defineProps<Props>();

const geoIp = computed(() => props.item.geoIp);
const ip = computed(() => props.item.ip);

const tooltip = computed(() => {
    return `${geoIp.value.city}, ${geoIp.value.regionName ? `${geoIp.value.regionName}, ` : ''}${geoIp.value.countryName}`;
});
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellLogGeoIp: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellLogGeoIp' };
</script>

<template>
    <div class="table-cell-location" v-b-tooltip.hover.top="{customClass: 'tooltip' }" :title="tooltip">
        <CountryFlag class="mr-2" :flag="geoIp.country" />
        <span>{{ ip }}</span>
    </div>
</template>

<style scoped lang="scss">
.tooltip {
  max-width: 250px !important;
  font-size: 12px;
  padding: 10px;
}
.table-cell-location {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
</style>