var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.top",
          value: { customClass: "tooltip" },
          expression: "{ customClass: 'tooltip' }",
          modifiers: { hover: true, top: true },
        },
      ],
      staticClass: "table-cell-device",
      attrs: { title: _setup.tooltip },
    },
    [
      _setup.browser?.name === "Chrome"
        ? _c("span", [_c("Icon", { attrs: { icon: _setup.faChrome } })], 1)
        : _setup.browser?.name === "Safari"
        ? _c("span", [_c("Icon", { attrs: { icon: _setup.faSafari } })], 1)
        : _setup.browser?.name === "Firefox"
        ? _c(
            "span",
            [_c("Icon", { attrs: { icon: _setup.faFirefoxBrowser } })],
            1
          )
        : _setup.browser?.name === "Opera" ||
          _setup.browser?.name === "Opera GX"
        ? _c("span", [_c("Icon", { attrs: { icon: _setup.faOpera } })], 1)
        : _setup.browser?.name === "Edge"
        ? _c("span", [_c("Icon", { attrs: { icon: _setup.faEdge } })], 1)
        : _setup.browser?.name === "Brave"
        ? _c("span", [_c("Icon", { attrs: { icon: _setup.faBrave } })], 1)
        : _setup.browser?.name === "Electron"
        ? _c("span", [_c("Icon", { attrs: { icon: _setup.faAtomSimple } })], 1)
        : _setup.browser?.name === "Unity"
        ? _c("span", [_c("Icon", { attrs: { icon: _setup.faUnity } })], 1)
        : _vm._e(),
      _vm._v(" "),
      _c("span", { staticClass: "ml-2" }, [
        _vm._v(" " + _vm._s(_setup.browser?.name)),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }