<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellUserStatus: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellUserStatus' };
</script>

<script setup lang="ts">
import { computed } from 'vue';

import type { User } from '@models';

/**
 * `TableCellUserStatus` properties.
 */
export interface Props {
  item: User;
}

const props = defineProps<Props>();

const deleting = computed(() => {
  return !!props.item.deletedAt;
});

const blocked = computed(() => {
  return !props.item.active;
});

const deletedAtText = computed(() => {
  let text;

  if (props.item.expires) {
    const deletionDate = new Date(props.item.expires * 1_000).toLocaleString();

    text = `User has been marked for deletion and will be permanently deleted on ${deletionDate}.`;
  } else {
    text = 'User has been marked for deletion.';
  }

  return text;
});
</script>

<template>
  <div class="user-status-table-cell">
    <span
      v-if="deleting"
      v-b-tooltip.hover
      class="deleted"
      :title="deletedAtText"
    >
      DELETING
    </span>

    <span
      v-else-if="blocked"
      v-b-tooltip.hover
      class="blocked"
      title="User has been blocked from logging in for suspicious activity"
    >
      BLOCKED
    </span>

    <span
      v-else
      v-b-tooltip.hover
      title="User account is active and in good standing"
      class="enabled"
    >
      ACTIVE
    </span>
  </div>
</template>

<style scoped lang="scss">
.deleted {
  color: var(--warning);
}

.blocked {
  color: var(--danger);
}

.enabled {
  color: var(--success);
}
</style>
