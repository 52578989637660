import { Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { modals } from '@modals';
import { Invoice } from '@models';
import * as currencies from '@utils/currencies';

import TableCellInvoiceNumber from '@components/TableCells/TableCellInvoiceNumber.vue';
import TableCellInvoiceStatus from '@components/TableCells/TableCellInvoiceStatus.vue';

import { faEye } from '@icons/solid/faEye';

const columns: TablePanel.Column<Invoice>[] = [
  {
    key: 'id',
    label: 'ID',
    value: (invoice) => {
      return invoice.invoiceNumber ? invoice.invoiceNumber : invoice.id;
    },
    component: TableCellInvoiceNumber,
  },
  {
    key: 'institution',
    label: 'Institution',
    value: ({ organization }) => organization?.name,
  },
  {
    key: 'createdAt',
    label: 'Created',
    type: 'date',
    value: 'createdAt',
  },
  {
    key: 'dueDate',
    label: 'Due Date',
    type: 'date',
    value: 'dueDate',
  },
  {
    key: 'status',
    label: 'Status',
    value: 'status',
    component: TableCellInvoiceStatus,
  },
  {
    key: 'amount',
    label: 'Total',
    value: ({ amount, currency }) => {
      return currencies.format({
        amount,
        currency,
        unitType: 'smallest',
      });
    },
  },
  {
    key: 'seats',
    label: 'Seats',
    value: 'seats',
  },
];

const operations: TablePanel.Row.Operation<Invoice>[] = [
  {
    label: 'View',
    icon: faEye,
    fn: ({ id }) => {
      void modals.invoice.view({ invoiceId: id });
    },
  },
];

@Module({ namespaced: true })
export class OrganizationInvoicesTablePanel
  extends TablePanel<Invoice>
  implements TablePanel.Props<Invoice>
{
  readonly module = 'invoices';
  readonly loadAction = 'list';
  readonly pk = 'id';
  readonly label = 'INSTITUTION INVOICES';
  readonly columns = columns;
  readonly operations = operations;
  readonly tableSortType = 'createdAt';
  readonly tableSortReverse = true;

  private get billingType() {
    return this.context.rootState.me.organization?.billingType ?? null;
  }

  get enabled() {
    if (this.isActiveRole(9)) return true;

    return (
      this.isActiveRole(5) &&
      (this.billingType === 'BillMeLater' || this.billingType === 'SiteLicense')
    );
  }

  readonly progressive = false;
}
