<script setup lang="ts">
import { computed } from 'vue';

import type { OutputLogEvent } from '@models';

import { faWindows } from '@icons/brands/faWindows';
import { faApple } from '@icons/brands/faApple';
import { faLinux } from '@icons/brands/faLinux';
import { faChrome } from '@icons/brands/faChrome';
import { faQuestion } from '@icons/solid/faQuestion';
import { faAndroid } from '@icons/brands/faAndroid';
/**
 * `TableCellLogOperatingSystem` component properties.
 */
export interface Props {
    item: OutputLogEvent;
}

const props = defineProps<Props>();

const os = computed(() => props.item.os);

const tooltip = computed(() => {
    return os.value ? `${os.value.name} ${os.value.version}` : 'Unknown';
});
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellLogOperatingSystem: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellLogOperatingSystem' };
</script>

<template>
   <div class="table-cell-log-operating-system" v-b-tooltip.hover.top="{customClass: 'custom-tooltip' }" :title="tooltip">
    <span v-if="os?.name === 'Windows'">
        <Icon :icon="faWindows" />
    </span>
    <span v-else-if="os?.name === 'macOS'">
        <Icon :icon="faApple" />
    </span>
    <span v-else-if="os?.name === 'Linux'">
        <Icon :icon="faLinux" />
    </span>
    <span v-else-if="os?.name === 'Chrome'">
        <Icon :icon="faChrome" />
    </span>
    <span v-else-if="os?.name === 'iOS'">
        <Icon :icon="faApple" />
    </span>
    <span v-else-if="os?.name === 'Android'">
        <Icon :icon="faAndroid" />
    </span>
    <span v-else>
        <Icon :icon="faQuestion" />
    </span>

    <span class="ml-2"> {{ os?.name }}</span>
   </div>
</template>

<style scoped lang="scss">
.custom-tooltip {
    max-width: 250px !important;
    font-size: 12px;
    padding: 10px;
}
.table-cell-log-operating-system {
    display: flex;
}
</style>