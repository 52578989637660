<template>
  <div>
    {{ organizationName }}
    <span v-if="isTrial" class="trial-badge">Trial</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import { Organization } from '@models';

/**
 * `TableCellOrganizationName` component properties.
 */
export interface Props {
  /** ... */
  item: Organization;
}

const props = defineProps<Props>();

const organizationName = computed(() => {
  const { item } = props;

  return item.name;
});

const isTrial = computed(() => {
  const { item } = props;

  return item.isTrial;
});
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellOrganizationName: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellOrganizationName' };
</script>

<style scoped>
.trial-badge {
  background-color: #da9f29;
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  margin-left: 4px;
} 
</style>
