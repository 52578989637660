<template>
    <div class="table-cell-log-level">
        <span v-b-tooltip.hover.right="tooltip" :class="['table-cell-log-level', level]">[{{ level.toLocaleUpperCase() }}]</span>
    </div>
</template>


<script setup lang="ts">
import { computed } from 'vue';

import type { OutputLogEvent } from '@models';

/**
 * `TableCellLogLevel` component properties.
 */
export interface Props {
  item: OutputLogEvent;
}


const props = defineProps<Props>();

const level = computed(() => props.item.level);
const statusCode = computed(() => props.item.statusCode);

const tooltip = computed(() => {
  return `Status Code: ${statusCode.value}`;
});
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellLogLevel: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellLogLevel' };
</script>

<style scoped lang="scss">
.table-cell-log-level {
    &.info {
        color: var(--primary);
    }

    &.error {
        color: var(--danger);
    }

    &.warn {
        color: var(--warning);
    }
}
</style>