import * as models from '@models';
import { store, Users } from '@store';

import { openModal } from '../open-modal';
import { GetItemProps } from '../types';

/**
 * ...
 */
export interface InviteUserModalOptions {
  organizationName?: models.Organization['name'];
  targetOrganizationId?: models.Organization['id'];
  targetEmails?: string[]; // Allow to preload emails
}

/**
 * Open Invite User modal.
 *
 * @returns Modal promise.
 */
export async function invite(options?: InviteUserModalOptions) {
  let title;

  if (options?.organizationName) {
    title = `Invite Users to ${options?.organizationName}`;
  } else {
    title = 'INVITE USERS';
  }

  await openModal.safe({
    title,
    props: { ...options },
    component: () => import('./InviteUsers.vue'),
  });
}

/**
 * Alias of {@link invite}.
 *
 * @deprecated
 */
export const create = invite;

/**
 * ...
 */
export interface EditUserModalOptions {
  user: models.User;
}

/**
 * Open Edit User modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function edit(options: EditUserModalOptions) {
  await openModal.safe({
    title: 'EDIT USER',
    props: { ...options },
    component: () => import('./EditUser/index.vue'),
  });
}

/**
 * ...
 */
export interface ViewUserDetailsModalOptions {
  user: models.User;
}

/**
 * Open View User Details modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function details(options: ViewUserDetailsModalOptions) {
  await openModal.safe({
    title: 'USER DETAILS',
    props: { ...options },
    size: 'lg',
    component: () => import('./UserDetails'),
  });
}

/**
 * ...
 */
export interface InviteStudentModalOptions {
  targetCourseId?: models.Course['id'];
}

/**
 * Open Invite Student modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function inviteStudent(options?: InviteStudentModalOptions) {
  await openModal.safe({
    title: 'INVITE STUDENT(S)',
    props: { ...(options ?? {}) },
    component: () => import('./InviteStudent.vue'),
  });
}

/**
 * Open Invite Indie Operator modal.
 *
 * @returns Modal promise.
 */
export async function inviteIndieOperator() {
  await openModal.safe({
    title: 'INVITE INDEPENDENT OPERATOR',
    component: () => import('./InviteIndependentOperator.vue'),
  });
}

/**
 * ...
 */
export interface InviteResellerModalOptions {
  resellerId?: models.Reseller['id'];
}

/**
 * Open Invite Reseller modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function inviteReseller(options?: InviteResellerModalOptions) {
  await openModal.safe({
    title: 'INVITE RESELLER USER',
    props: { ...(options ?? {}) },
    component: () => import('./InviteReseller.vue'),
  });
}

/**
 * Open Invite Reviewers modal.
 *
 * @returns Modal promise.
 */
export async function inviteReviewers() {
  await openModal.safe({
    title: 'INVITE REVIEWERS',
    // props: { ...(options || {}) },
    component: () => import('./InviteReviewers.vue'),
  });
}

/**
 * Open Invite LAS Admin modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function inviteLasAdmin() {
  await openModal.safe({
    title: 'INVITE LAS ADMIN(S)',
    component: () => import('./InviteLasAdmin.vue'),
  });
}

/**
 * ...
 */
export interface LinkDroneLogbookModalOptions {
  organizationId: models.Organization['id'];
  oldOrganizationId: models.Organization['oldId'];
}

/**
 * Open Link Drone Logbook modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function linkDroneLogbook(options?: LinkDroneLogbookModalOptions) {
  const modalOptions: openModal.Options = {
    title: 'LINK DRONE LOGBOOK ACCOUNT',
    size: 'lg',
    props: { ...(options ?? {}) },
    component: () => import('./LinkDroneLogbook.vue'),
  };

  let success = true;

  try {
    await openModal(modalOptions);
  } catch {
    success = false;
  }

  return { success };
}

/**
 * ...
 */
export interface LogoutModalOptions {
  message: string;
}

/**
 * Open Logout modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function logout(options: LogoutModalOptions) {
  await openModal.safe({
    title: 'LOGOUT',
    props: { ...options },
    component: () => import('./Logout.vue'),
  });
}

/**
 * ...
 */
export type RemoveStudentFromInstitutionModalOptions = Users.GetActionOptions;

/**
 * Open Remove Student from Institution modal.
 *
 * @deprecated in favor of remove user from institution
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function removeStudentFromInstitution(
  options: RemoveStudentFromInstitutionModalOptions,
) {
  const user = await store.dispatch('users/get', options);

  await openModal.safe({
    title: 'REMOVE STUDENT FROM INSTITUTION',
    props: { user },
    component: () => import('./RemoveStudentFromInstitution.vue'),
  });
}

/**
 * ...
 */
export type RemoveInstructorFromInstitutionModalOptions =
  Users.GetActionOptions;

/**
 * Open Remove Instructor from Institution modal.
 *
 * @deprecated in favor of remove user from institution
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function removeInstructorFromInstitution(
  options: RemoveInstructorFromInstitutionModalOptions,
) {
  const user = await store.dispatch('users/get', options);

  await openModal.safe({
    title: 'REMOVE INSTRUCTOR FROM INSTITUTION',
    props: { user },
    component: () => import('./RemoveInstructorFromInstitution.vue'),
  });
}

/**
 * ...
 */
export interface AddCourseModalOptions {
  user: models.User;
  targetOrganizationName: models.Organization['name'];
  targetOrganizationId: models.Organization['id'];
}

/**
 * Open Add Course modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function addCourse(options: AddCourseModalOptions) {
  await openModal.safe({
    title: `Adding Course to ${options.user.email}`,
    props: { ...options },
    component: () => import('./AddCourse.vue'),
  });
}

/**
 * ...
 */
export interface RemoveUserFromInstitutionModalOptions {
  user: models.User;
}

export interface RemoveUserModalResult {
  userRemoved: boolean;
}

/**
 * Open Remove User from Institution modal.
 *
 * @param options Modal options.
 * @returns Modal promise that resolves to a {@link RemoveUserModalResult}.
 */
export async function removeUserFromInstitution(
  options: RemoveUserFromInstitutionModalOptions,
) {
  let result;

  try {
    result = await openModal<RemoveUserModalResult>({
      title: 'REMOVE USER FROM INSTITUTION',
      props: { ...options },
      component: () => import('./RemoveUserFromInstitution.vue'),
    });
  } catch {
    result = null;
  }

  const userRemoved = !!result?.userRemoved;

  return { userRemoved } as RemoveUserModalResult;
}

/**
 * ...
 */
export interface DeleteUserModalOptions {
  user: models.User;
}

export interface UserDeletedModalResult {
  userDeleted: boolean;
}

/**
 * Open Delete User View modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function deleteUser(options: DeleteUserModalOptions) {
  let userDeletedResponse: UserDeletedModalResult | null = null;

  try {
    userDeletedResponse = (await openModal({
      title: 'DELETE USER',
      props: { ...options },
      component: () => import('./DeleteUser.vue'),
    })) as UserDeletedModalResult;
  } catch {
    //empty catch
  }

  return { userDeleted: userDeletedResponse?.userDeleted };
}

export interface ChangePasswordModalOptions {
  userId: string;
}

/**
 * Open Change Password View modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function changePassword(options: ChangePasswordModalOptions) {
  await openModal.safe({
    title: 'CHANGE PASSWORD',
    props: { ...options },
    component: () => import('./ChangePassword.vue'),
  });
}

/**
 * Open Change Password V2 View modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function changePasswordV2(options: ChangePasswordModalOptions) {
  await openModal.safe({
    title: 'CHANGE PASSWORD',
    props: { ...options },
    component: () => import('./ChangePasswordV2.vue'),
  });
}

/**
 * Open Reseller View modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function resellerViewUser(userId: number) {
  const gip: GetItemProps = {
    storeName: 'users',
    key: 'user',
    id: userId,
    getter: (id: number) => store.dispatch('users/get', id),
  };

  await openModal.safe({
    title: 'USER DETAILS',
    component: () => import('./ResellerViewUser.vue'),
    getItemProps: [gip],
  });
}

/**
 * ...
 */
export interface CoursesModalOptions {
  userId: models.User['id'];
}

/**
 * Open User Courses modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function courses(options: CoursesModalOptions) {
  await openModal.safe({
    title: 'USER COURSES',
    size: 'lg',
    props: { ...options },
    component: () => import('./UserCourses.vue'),
  });
}

/**
 * ...
 */
export interface OrdersModalOptions {
  userId: models.User['id'];
}

/**
 * Open User Orders modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function orders(options: OrdersModalOptions) {
  await openModal.safe({
    title: 'USER ORDERS',
    size: 'lg',
    props: { ...options },
    component: () => import('./UserOrders.vue'),
  });
}

/**
 * ...
 */
export interface ReportsModalOptions {
  user: models.User;
}

/**
 * Open User Reports modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function reports(options: ReportsModalOptions) {
  await openModal.safe({
    title: 'USER REPORTS',
    size: 'lg',
    props: { ...options },
    component: () => import('./UserReports.vue'),
  });
}

/** ... */
export interface RolesModalOptions {
  userId: models.User['id'];
}

/**
 * Open User Roles modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function roles(options: RolesModalOptions) {
  await openModal.safe({
    title: 'USER ROLES',
    size: 'lg',
    props: { ...options },
    component: () => import('./UserRoles.vue'),
  });
}

/**
 * ...
 */
export interface AddLicenseModalOptions {
  user: models.User;
  licenses: models.License[];
}

/**
 * Open Add User License modal.
 *
 * @param options Modal options.
 * @returns Modal promise.
 */
export async function addLicense(options: AddLicenseModalOptions) {
  const modalOptions: openModal.Options = {
    title: 'ADD USER LICENSE',
    size: 'lg',
    props: { ...options },
    component: () => import('./AddLicense.vue'),
  };

  let output = null;

  try {
    output = await openModal(modalOptions);
  } catch {
    return;
  }

  return output;
}

/**
 * ...
 */
export interface AddRoleModalOptions {
  user: models.User;
}

/**
 * Open Add Role Modal.
 */
export async function addRole(options: AddRoleModalOptions) {
  const modalOptions: openModal.Options = {
    title: 'ADD USER ROLE',
    props: { ...options },
    component: () => import('./AddRole.vue'),
  };

  let output: models.Role | undefined;

  try {
    output = await openModal<models.Role>(modalOptions);
  } catch {
    return null;
  }

  return output ?? null;
}

/**
 * ...
 */
export interface UpdateRoleModalOptions {
  user: models.User;
  roleId: models.Role['id'];
}

/**
 * Open Add Role Modal.
 */
export async function updateRole(options: UpdateRoleModalOptions) {
  const props = {
    user: options.user,
    initialRoleId: options.roleId,
  };

  const modalOptions: openModal.Options = {
    title: 'UPDATE USER ROLE',
    props,
    component: () => import('./UpdateRole.vue'),
  };

  let output: models.Role | undefined;

  try {
    output = await openModal<models.Role>(modalOptions);
  } catch {
    return null;
  }

  return output ?? null;
}
