<script setup lang="ts">
import { computed } from 'vue';
import type { OutputLogEvent } from '@models';

/**
 * `TableCellLogAction` component properties.
 */
export interface Props {
    item: OutputLogEvent;
}

const props = defineProps<Props>();

const action = computed(() => props.item.action);
const url = computed(() => props.item.url);
const method = computed(() => props.item.method);

const tooltip = computed(() => {
    return `${method.value} ${url.value}`;
});
</script>

<script lang="ts">
declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    TableCellLogAction: ComponentWithProps<Props>;
  }
}

export default { name: 'TableCellLogAction' };
</script>

<template>
    <div class="table-cell-log-action">
        <span v-b-tooltip.hover.top="{customClass: 'custom-tooltip' }" :title="tooltip">{{ action }}</span>
    </div>
</template>

<style scoped lang="scss">
.custom-tooltip {
    max-width: none !important;
    width: auto !important;
    white-space: nowrap !important;
    font-size: 12px;
    padding: 10px;
}
:deep(.tooltip-inner) {
  display: inline-block !important;
  width: auto !important;
  max-width: none !important;
  background-color: rgba(0, 0, 0, 0.85) !important;
  padding: 20px 20px !important;
}


.table-cell-log-action {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}
</style>
