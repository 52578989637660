import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import { find, findIndex } from 'lodash';

import { api } from '@api';
import { Module as ModuleData } from '@models';
import { Scene as SceneData } from '@models';
import { auth, RoleId } from '@auth';
import { Root } from '@store';
import { isString } from '@tools/type-guards';

declare module '@vuex/core' {
  export interface Getters {
    'modules/findById': Modules['findById'];
  }

  export interface CommitMap {
    'modules/SET': Modules['SET'];
    'modules/ADD': Modules['ADD'];
    'modules/UPDATE': Modules['UPDATE'];
    'modules/DELETE': Modules['DELETE'];
    'modules/CLEAR': Modules['CLEAR'];
  }

  export interface DispatchMap {
    'modules/list': Modules['list'];
    'modules/get': Modules['get'];
    'modules/create': Modules['create'];
    'modules/update': Modules['update'];
    'modules/del': Modules['del'];
  }
}

@Module({ namespaced: true })
export class Modules
  extends VuexModule<Modules.State, Root.State>
  implements Modules.State
{
  items: ModuleData[] = [];

  /** ... */
  get findById() {
    return (id: string) => find(this.items, { id });
  }

  //#region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: Modules.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: Modules.AddMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.id });

    if (i !== -1) {
      items[i] = options;
    } else {
      items.push(options);
    }

    this.items = items;
  }

  /**
   * ...
   */
  @Mutation
  UPDATE(options: Modules.UpdateMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.moduleId });

    if (i === -1) {
      throw new Error(`Module with ID ${options.moduleId} not found.`);
    }

    for (const key in options) items[i][key] = options[key];

    this.items = items;
  }

  /**
   * ...
   */
  @Mutation
  DELETE(options: Modules.DeleteMutationOptions) {
    const index = findIndex(this.items, { id: options.moduleId });

    if (index === -1) {
      throw new Error(`Module with ID ${options.moduleId} not found.`);
    }

    this.items = this.items.filter((_, i) => i !== index);
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  //#endregion Mutations

  //#region Actions

  /**
   * ...
   */
  @Action
  async list() {
    const { me } = this.context.rootState;

    let data: ModuleData[] = [];
    let sceneData: SceneData[] = [];

    if (auth.isActiveRole(RoleId.LasAdmin)) {
      data = await api.modules.list();
      sceneData = await api.scenes.list();

      // Add scene references to each module
      data = data.map(module => ({
        ...module,
        scenes: sceneData
          .filter(scene => scene.modules.some(m => m.id === module.id))
          .map(scene => ({ id: scene.id, name: scene.name }))
      }));
    } else if (auth.isActiveRole(RoleId.Instructor, RoleId.InstitutionAdmin)) {
      const organizationId = me.organization?.id;

      if (!isString(organizationId)) {
        return console.error(
          `Expected User.Institution.Id to be a number. Got ${typeof organizationId} instead`,
        );
      }

      sceneData = await api.scenes.listByOrganization({ organizationId });

      data = sceneData.flatMap((scene) => {
        return scene.modules;
      });
    }

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async get(options: Modules.GetActionOptions) {
    const data = await api.modules.get(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async create(options: Modules.CreateActionOptions) {
    const data = await api.modules.create(options);

    this.context.commit('ADD', data);

    return data;
  }

  /**
   * ...
   */
  @Action
  async update(options: Modules.UpdateActionOptions) {
    await api.modules.update(options);

    return (await this.context.dispatch('get', options)) as ModuleData;
  }

  /**
   * ...
   */
  @Action
  async del(options: Modules.DeleteActionOptions) {
    await api.modules.del(options);

    this.context.commit('DELETE', options);
  }

  //#endregion Actions
}

export namespace Modules {
  /**
   * ...
   */
  export interface State {
    items: ModuleData[];
  }

  /**
   * ...
   */
  export interface SetMutationOptions {
    data: ModuleData[];
  }

  /** ... */
  export type AddMutationOptions = ModuleData;
  /** ... */
  export type UpdateMutationOptions = api.modules.UpdateOptions;
  /** ... */
  export type DeleteMutationOptions = api.modules.DeleteOptions;
  /** ... */
  export type GetActionOptions = api.modules.GetOptions;
  /** ... */
  export type CreateActionOptions = api.modules.CreateOptions;
  /** ... */
  export type UpdateActionOptions = api.modules.UpdateOptions;
  /** ... */
  export type DeleteActionOptions = api.modules.DeleteOptions;
}

export default Modules;
