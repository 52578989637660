import { api } from '@api';

export type CartItem = api.payments.CreateCheckoutSessionOptions.CartItem;

export type Context =
  | 'shop'
  | 'student-seat'
  | 'organization-seats'
  | 'student-certifications';

/**
 * {@link initiateUserCheckout User checkout} configuration options.
 */
export interface UserCheckoutOptions
  extends api.payments.CreateCheckoutSessionOptions {
  context?: Context;
}

/**
 * Initiate checkout process for the current site user given a list of products.
 * This will create a remote Stripe checkout session before redirecting the user
 * to a dedicated checkout webpage hosted by Stripe.
 *
 * @param options User checkout options data object.
 */
export async function initiateUserCheckout(options: UserCheckoutOptions) {
  const { sessionUrl } = await api.payments.createSession(options);

  if (!sessionUrl) {
    throw new Error(
      '[pos:initiateUserCheckout] request to create session did not result in a valid session URL.',
    );
  }

  window.location.assign(sessionUrl);
}

export interface CheckoutSessionInfo
  extends api.payments.CreateCheckoutSessionResponse {
  sessionId?: string;
}

/**
 * Simply creates a check session and returns the {@link CheckoutSessionInfo},
 * which includes the session ID parsed from the `sessionUrl` (if it exists).
 * It is up to the consumer of this function to utilize the created session,
 * including using the `sessionUrl` to redirect to Stripe to start the
 * transaction.
 *
 * @param options User checkout options data object.
 * @returns The {@link CheckoutSessionInfo} data object.
 */
export async function createCheckoutSession(options: UserCheckoutOptions) {
  const info: CheckoutSessionInfo = await api.payments.createSession(options);

  if (info.sessionUrl) {
    const sessionId = info.sessionUrl.split('/').at(-1)?.split('#').at(0);

    if (!sessionId) {
      throw new Error(
        '[pos:createCheckoutSession] failed to parse checkout session ID from existing session URL.',
      );
    }

    info.sessionId = sessionId;
  }

  return info;
}
