import { Module, VuexModule, Mutation, Action } from '@vuex/decorators';
import { find, findIndex } from 'lodash';

import { api } from '@api';
import { Invoice } from '@models';
import { auth } from '@auth';
import { Root } from '@store';

declare module '@vuex/core' {
  export interface Getters {
    'invoices/findById': Invoices['findById'];
  }

  export interface CommitMap {
    'invoices/SET': Invoices['SET'];
    'invoices/ADD': Invoices['ADD'];
    'invoices/UPDATE': Invoices['UPDATE'];
    'invoices/CLEAR': Invoices['CLEAR'];
  }

  export interface DispatchMap {
    'invoices/list': Invoices['list'];
    'invoices/listByOrganization': Invoices['listByOrganization'];
    'invoices/get': Invoices['get'];
  }
}

@Module({ namespaced: true })
export class Invoices
  extends VuexModule<Invoices.State, Root.State>
  implements Invoices.State
{
  items: Invoice[] = [];

  /** ... */
  get findById() {
    return (id: string) => find(this.items, { id });
  }

  //#region Mutations

  /**
   * ...
   */
  @Mutation
  SET(options: Invoices.SetMutationOptions) {
    this.items = options.data;
  }

  /**
   * ...
   */
  @Mutation
  ADD(options: Invoices.AddMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.id });

    if (i !== -1) {
      items[i] = options;
    } else {
      items.push(options);
    }

    this.items = items;
  }

  /**
   * ...
   */
  @Mutation
  UPDATE(options: Invoices.UpdateMutationOptions) {
    const items = [...this.items];

    const i = findIndex(items, { id: options.invoiceId });

    if (i === -1) {
      throw new Error(`Invoice with ID ${options.invoiceId} not found.`);
    }

    for (const key in options) items[i][key] = options[key];

    this.items = items;
  }

  /**
   * ...
   */
  @Mutation
  CLEAR() {
    this.items = [];
  }

  //#endregion Mutations

  //#region Actions

  /**
   * ...
   */
  @Action
  async list() {
    const { me } = this.context.rootState;

    let data: Invoice[] = [];

    if (auth.isActiveRole(9)) {
      data = await api.invoices.list();
    } else if (auth.isActiveRole(4, 5)) {
      const organizationId = me.organization?.id;

      if (typeof organizationId !== 'string') {
        /* eslint-disable-next-line no-console */
        console.error(
          `Expected User.organization.id to be a number. Got ${typeof organizationId} instead`,
        );

        return null;
      }

      data = await api.invoices.listByOrganization({ organizationId });
    }

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async listByOrganization(options?: Invoices.ListByOrganizationOptions) {
    const { me } = this.context.rootState;

    if (!me.selectedRole) {
      /* eslint-disable-next-line no-console */
      console.error('No User Detected');

      return null;
    }

    if (!options) {
      const organizationId = me.selectedRole.organization?.id;

      if (typeof organizationId !== 'string') {
        /* eslint-disable-next-line no-console */
        console.error(
          'The current user does not have an account ID associated with their current role.',
        );

        return null;
      }

      options = { organizationId };
    }

    const data = await api.invoices.listByOrganization(options);

    this.context.commit('SET', { data });

    return this.items;
  }

  /**
   * ...
   */
  @Action
  async get(options: Invoices.GetActionOptions) {
    const data = await api.invoices.get(options);

    this.context.commit('ADD', data);

    return data;
  }

  //#endregion Actions
}

export namespace Invoices {
  /**
   * ...
   */
  export interface State {
    items: Invoice[];
  }

  /**
   * ...
   */
  export interface SetMutationOptions {
    data: Invoice[];
  }

  /** ... */
  export type AddMutationOptions = Invoice;
  /** ... */
  export type UpdateMutationOptions = api.invoices.UpdateOptions;
  /** ... */
  export type ListByOrganizationOptions =
    api.invoices.ListByOrganizationOptions;
  /** ... */
  export type GetActionOptions = api.invoices.GetOptions;
}

export default Invoices;
