var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.top",
          value: { customClass: "tooltip" },
          expression: "{customClass: 'tooltip' }",
          modifiers: { hover: true, top: true },
        },
      ],
      staticClass: "table-cell-location",
      attrs: { title: _setup.tooltip },
    },
    [
      _c(_setup.CountryFlag, {
        staticClass: "mr-2",
        attrs: { flag: _setup.geoIp.country },
      }),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_setup.ip))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }