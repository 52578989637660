import { Module } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { modals } from '@modals';
import { auth, RoleId } from '@auth';
import { OutputLogEvent } from '@models';
import { isString } from '@tools/type-guards';

// import TableCellSuccess from '@components/TableCells/TableCellSuccess.vue';
// import TableCellListItems from '@components/TableCells/TableCellListItems.vue';
import TableCellLogLevel from '@components/TableCells/TableCellLogLevel.vue';
import TableCellLogDevice from '@components/TableCells/TableCellLogDevice.vue';
import TableCellLogGeoIp from '@components/TableCells/TableCellLogGeoIp.vue';
import TableCellLogAction from '@components/TableCells/TableCellLogAction.vue';
import TableCellLogOperatingSystem from '@components/TableCells/TableCellLogOperatingSystem.vue';

import { faCopy } from '@icons/solid/faCopy';
import { faLayerGroup } from '@icons/solid/faLayerGroup';
import { faMapLocationDot } from '@icons/solid/faMapLocationDot';
import { faAws } from '@icons/brands/faAws';

const columns: TablePanel.Column<OutputLogEvent>[] = [
  {
    key: 'level',
    label: 'Level',
    component: TableCellLogLevel,
    value: (item) => {
      return item;
    },
  },
  {
    key: 'timestamp',
    label: 'Timestamp',
    sortByFormatted: true,
    value: ({ timestamp }) => {
      return new Date(timestamp).toLocaleString('en-US', {
        timeZoneName: 'short',
      });
    },
  },
  {
    key: 'action',
    label: 'Action',
    component: TableCellLogAction,
    value: (item) => {
      return item;
    },
  },
  {
    key: 'os',
    label: 'OS',
    component: TableCellLogOperatingSystem,
    value: (item) => {
      return item;
    },
  },
  {
    key: 'device',
    label: 'Device',
    component: TableCellLogDevice,
    value: (item) => {
      return item;
    },
  },
  {
    key: 'geoIp',
    label: 'Geo Ip',
    component: TableCellLogGeoIp,
    value: (item) => {
      return item;
    },
  },
  {
    key: 'application',
    label: 'Application',
    value: ({ application }) => {
      return application;
    },
  },
];

const operations: TablePanel.Row.Operation<OutputLogEvent>[] = [
  {
    label: 'View',
    icon: faCopy,
    fn: viewFullLog,
  },
  {
    label: 'Betterstack',
    icon: faLayerGroup,
    fn: (item) => {
      window.open(item.betterstack, 'blank');
    }
  },
  {
    label: 'CloudWatch',
    icon: faAws,
    fn: (item) => {
      window.open(item.cloudWatch, 'blank');
    }
  },
  {
    label: 'Google Maps',
    icon: faMapLocationDot,
    fn: (item) => {
      window.open(`https://maps.google.com/?q=${item.location.latitude},${item.location.longitude}`, 'blank');
    }
  },
  {
    label: 'Apple Maps',
    icon: faMapLocationDot,
    fn: (item) => {
      window.open(`https://beta.maps.apple.com/?ll=${item.location.latitude},${item.location.longitude}`, 'blank');
    }
  }
];

@Module({ namespaced: true })
export class OutputLogEventsTablePanel
  extends TablePanel<OutputLogEvent>
  implements TablePanel.Props<OutputLogEvent>
{
  onPageChanged?: TablePanel.OnPageChangedCallback<OutputLogEvent>;
  readonly module = 'outputLogEvents';
  readonly pk = 'id';
  readonly loadPageAction = 'loadPage';
  readonly label = 'USER LOGS';
  readonly columns = columns;
  readonly operations = operations;
  readonly loadOnOpen = false;
  readonly infoMessage =
    'Currently, can only query logs from the last 30 days';

    get enabled() {
      return this.isActiveRole(RoleId.Reseller, RoleId.LasAdmin);
    }
  
    get progressive() {
      return this.isActiveRole(RoleId.LasAdmin);
    }
}

/**
 * View the full contents of a log via a modal.
 *
 * @param event ...
 */
export function viewFullLog(event: OutputLogEvent) {
  let value = event;
  let requester = event.requester.email;

  const time = new Date(event.timestamp).toUTCString();

  void modals.util.viewValue({
    value,
    title: `LOG EVENT (${requester}) - ${time}`,
  });
}
