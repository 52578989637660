<script lang="ts">
import { ref, computed, onUnmounted } from 'vue';

import { useRouter } from '@router';
import { useStore } from '@store';
import { externalLinks } from '@values';

import LittleArmsStudios from '@components/SVGs/LittleArmsStudios.vue';
import Toothy from '@components/SVGs/Toothy.vue';

import { faApple } from '@icons/brands/faApple';
import { faWindows } from '@icons/brands/faWindows';
import { faEarthAmericas } from '@icons/solid/faEarthAmericas';

/** App version. */
const VERSION = process.env.PACKAGE_VERSION;

/**
 * Internal site link configuration.
 */
interface InternalSiteLink {
  key: string;
  label: string;
  route: string;
}

const COMPANY_LINKS: InternalSiteLink[] = [
  {
    key: 'about',
    label: 'About',
    route: 'about',
  },
  {
    key: 'terms',
    label: 'Terms',
    route: 'termsAndConditions',
  },
  {
    key: 'privacy',
    label: 'Privacy',
    route: 'privacyPolicy',
  },
  {
    key: 'cookies',
    label: 'Cookies',
    route: 'cookies',
  },
  {
    key: 'shipping',
    label: 'Shipping',
    route: 'shippingPolicy',
  },
  {
    key: 'returns',
    label: 'Returns',
    route: 'returns',
  },
];

const RESOURCE_LINKS = [
  externalLinks.roadmap,
  externalLinks.patchNotes,
  externalLinks.wiki,
  externalLinks.faqs,
];

const PLATFORM_LINKS = [
  externalLinks.enterpriseBilling,
  externalLinks.integrations,
  externalLinks.security,
  externalLinks.status,
];

const SOCIAL_LINKS = [
  externalLinks.facebook,
  externalLinks.twitter,
  externalLinks.instagram,
  externalLinks.linkedin,
  externalLinks.youtube,
];

export default { name: 'AppFooter' };
</script>

<script setup lang="ts">
/** Current year. */
const year = new Date().getFullYear();

const router = useRouter();
const store = useStore();

const routeName = ref(router.currentRoute.name ?? null);

onUnmounted(
  router.afterEach((to) => {
    routeName.value = to.name ?? null;
  }),
);

/** `true` if the footer should be rendered, otherwise `false`. */
const enabled = computed(() => {
  return (
    // Wait until initial route navigation has completed to avoid flashing
    // of footer on page load.
    store.state.initialNavigationCompleted &&
    routeName.value !== 'checkout' &&
    routeName.value !== 'publicProfile' &&
    routeName.value !== 'onboarding' &&
    !(routeName.value ?? '').startsWith('dashboard')
  );
});
</script>

<template>
  <Transition>
    <footer v-if="enabled">
      <b-container class="px-5 footer-container">
        <b-row class="py-4 justify-content-between">
          <b-col cols="auto" class="flex-wrap">
            <div style="margin-bottom: 20px">
              <a href="http://www.littlearmsstudios.com" target="_blank">
                <div class="little-arms-logo-wrapper">
                  <Toothy />
                  <LittleArmsStudios />
                </div>
              </a>
            </div>

            <div>
              <div>
                <h6 class="text-muted">Little Arms, Big Ideas!</h6>
              </div>

              <div style="max-width: 200px">
                <p class="text-muted" style="text-align: justify">
                  Fly without risking hardware, having to charge drone
                  batteries, and worrying about weather. Users can train
                  wherever and whenever they want.
                </p>
              </div>
            </div>
          </b-col>

          <b-col cols="auto" class="flex-wrap">
            <h4>Company</h4>

            <ul class="no-bullets">
              <li v-for="link in COMPANY_LINKS" :key="link.key" class="py-1">
                <RouterLink :to="{ name: link.route }">
                  {{ link.label }}
                </RouterLink>
              </li>
            </ul>
          </b-col>

          <b-col cols="auto" class="flex-wrap">
            <h4>Platform</h4>

            <ul class="no-bullets">
              <li class="py-1">
                <RouterLink :to="{ name: 'individuals' }">
                  Individual Pricing
                </RouterLink>
              </li>

              <li class="py-1">
                <RouterLink :to="{ name: 'enterprise' }">
                  Enterprise Pricing
                </RouterLink>
              </li>

              <li v-for="link in PLATFORM_LINKS" :key="link.id" class="py-1">
                <a :href="link.url" target="_blank">{{ link.title }}</a>
              </li>
            </ul>
          </b-col>

          <b-col cols="auto" class="flex-wrap">
            <h4>Resources</h4>

            <ul class="no-bullets">
              <li class="py-1">
                <RouterLink :to="{ name: 'contact' }">Contact</RouterLink>
              </li>

              <li v-for="link in RESOURCE_LINKS" :key="link.id" class="py-1">
                <a :href="link.url" target="_blank">{{ link.title }}</a>
              </li>
            </ul>
          </b-col>

          <b-col cols="auto" class="flex-wrap">
            <RouterLink class="mb-2" :to="{ name: 'systemRequirements' }">
              <div class="platform-logo-container-footer mb-2">
                <h6 class="pt-3 pl-3 pr-3">Requirements</h6>

                <div class="platform-logo-image-wrapper-footer">
                  <Icon class="fa-3x" :icon="faApple" />
                </div>

                <div class="platform-logo-image-wrapper-footer">
                  <Icon class="fa-3x" :icon="faWindows" />
                </div>
              </div>
            </RouterLink>

            <RouterLink :to="{ name: 'countryAvailability' }">
              <div class="platform-availability-container-footer">
                <h6 class="pt-3 pl-3 pr-3">Availability</h6>

                <div class="platform-logo-image-wrapper-footer">
                  <Icon class="fa-3x" :icon="faEarthAmericas" />
                </div>
              </div>
            </RouterLink>
          </b-col>
        </b-row>

        <b-row class="pt-1 pb-4 justify-content-between">
          <b-col cols="auto">
            <p class="text-muted m-0">
              <span>© Zephyr {{ year }}</span>
              <span class="m-2">|</span>
              <span>v{{ VERSION }}</span>
            </p>
          </b-col>

          <b-col cols="auto">
            <a
              v-for="link in SOCIAL_LINKS"
              :key="link.id"
              :href="link.url"
              class="social-link mx-2"
              target="_blank"
            >
              <Icon :icon="link.icon" />
            </a>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </Transition>
</template>

<style scoped lang="scss">
.little-arms-logo-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  gap: 10px;
}

.footer-container a:hover {
  text-decoration: none !important;
}

.platform-logo-container-footer {
  text-align: center;
  border-radius: 10px;

  @include app-theme-dark {
    border: 1px solid #ececec;
  }
  @include app-theme-light {
    border: 1px solid #2c303b;
  }
}

.platform-logo-container-footer:hover {
  opacity: 0.5;
  transition: opacity 0.33s;
}

a.available-footer:hover {
  text-decoration: none !important;
}

.platform-logo-image-wrapper-footer {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 20px;
  // padding: 15px;
  padding-left: 5px;
  padding-right: 5px;
}

.platform-availability-container-footer {
  text-align: center;

  border-radius: 10px;
  @include app-theme-dark {
    border: 1px solid #ececec;
  }
  @include app-theme-light {
    border: 1px solid #2c303b;
  }
}

.platform-availability-container-footer:hover {
  opacity: 0.5;
  transition: opacity 0.33s;
}

.little-arms-logo-wrapper:hover {
  opacity: 0.5;
  transition: opacity 0.33s;
}

footer {
  font-size: 0.8rem;
  background-color: var(--footer-bg);
  color: var(--footer-color);
  width: 100%;
  transform: translateY(0);
  transition: transform 0.5s;

  // @include v-from {
  //   transform: translateY(100%);
  // }

  @include v-enter {
    transform: translateY(100%);
  }

  @include v-leave-to {
    transition: none;
  }
}

a {
  color: inherit !important;
  white-space: nowrap;
  transition: opacity 0.25s;

  &:focus,
  &:hover {
    opacity: 0.5;
    text-decoration: line;
  }
}

hr {
  background: var(--text-primary);
  opacity: 0.25;
}

.social-link {
  font-size: 1.4rem;
}

.separator {
  margin: 0 6px;
}
</style>
