import * as models from '@models';

import { openModal } from '../open-modal';

/**
 * Delete items modal configuration options.
 */
export type DeleteItemsModalOptions =
  | { itemType: 'announcements'; items: models.Announcement[] }
  | { itemType: 'assignments'; items: models.Assignment[] }
  | { itemType: 'courses'; items: models.Course[] }
  | { itemType: 'drones'; items: models.Drone[] }
  | { itemType: 'extensionCodes'; items: models.ExtensionCode[] }
  | { itemType: 'organizations'; items: models.Organization[] }
  | { itemType: 'invites'; items: models.Invite[] }
  | { itemType: 'modules'; items: models.Module[] }
  | { itemType: 'products'; items: models.Product[] }
  | { itemType: 'scenes'; items: models.Scene[] }
  | { itemType: 'apiKeys'; items: models.ApiKey[] };

/**
 * Open Delete Items modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function deleteItems(options?: DeleteItemsModalOptions) {
  if (options?.itemType === 'organizations') {
    return await deleteOrganizations({ organizations: options.items });
  }

  if (options?.itemType === 'courses') {
    return await deleteCourses({ courses: options.items });
  }

  await openModal.safe({
    title: 'CONFIRM DELETION',
    props: { ...(options ?? {}) },
    component: () => import('./DeleteItems/index.vue'),
  });
}

/**
 * Delete Courses modal configuration options.
 */
export interface DeleteCoursesModalOptions {
  courses: models.Course[];
}

/**
 * Open Delete Courses modal.
 *
 * @returns Modal promise.
 */
export async function deleteCourses(options: DeleteCoursesModalOptions) {
  let title = 'DELETE COURSE';

  if (options.courses.length > 1) {
    title += 'S';
  }

  await openModal.safe({
    title,
    props: { ...options },
    component: () => import('./DeleteCourses.vue'),
  });
}

/**
 * Delete Courses Confirmation modal configuration options.
 */
export interface DeleteCoursesConfirmationModalOptions {
  courses: models.Course[];
  deleteStudents?: boolean;
  deleteReports?: boolean;
  deleteImmediately?: boolean;
  silent?: boolean;
}

/**
 * Open Delete Organizations Confirmation modal.
 *
 * @returns A promise that resolves to `true` if the user elected to continue
 * with the deletion process, or `false` if they chose not to.
 */
export async function deleteCoursesConfirmation(
  options: DeleteCoursesConfirmationModalOptions,
) {
  let shouldProceed;

  try {
    shouldProceed = await openModal({
      props: { ...options },
      component: () => import('./DeleteCoursesConfirmation.vue'),
    });
  } catch {
    shouldProceed = false;
  }

  return shouldProceed;
}

/**
 * Delete Organizations modal configuration options.
 */
export interface DeleteOrganizationsModalOptions {
  organizations: (string | models.Organization)[];
}

/**
 * Open Delete Organizations modal.
 *
 * @returns Modal promise.
 */
export async function deleteOrganizations(
  options: DeleteOrganizationsModalOptions,
) {
  let title = 'DELETE INSTITUTION';

  if (options.organizations.length > 1) {
    title += 'S';
  }

  await openModal.safe({
    title,
    props: { ...options },
    component: () => import('./DeleteOrganizations.vue'),
  });
}

/**
 * Delete Organizations Confirmation modal configuration options.
 */
export interface DeleteOrganizationsConfirmationModalOptions {
  organizations: models.Organization[];
  deleteStudents?: boolean;
  deleteReports?: boolean;
  deleteImmediately?: boolean;
  silent?: boolean;
}

/**
 * Open Delete Organizations Confirmation modal.
 *
 * @returns A promise that resolves to `true` if the user elected to continue
 * with the deletion process, or `false` if they chose not to.
 */
export async function deleteOrganizationsConfirmation(
  options: DeleteOrganizationsConfirmationModalOptions,
) {
  let shouldProceed;

  try {
    shouldProceed = await openModal({
      props: { ...options },
      component: () => import('./DeleteOrganizationsConfirmation.vue'),
    });
  } catch {
    shouldProceed = false;
  }

  return shouldProceed;
}

/**
 * Delete Users modal configuration options.
 */
export interface DeleteUsersModalOptions {
  users: (string | models.User)[];
}

/**
 * Open Delete Users modal.
 *
 * @returns Modal promise.
 */
export async function deleteUsers(options: DeleteUsersModalOptions) {
  let title = 'DELETE USER';

  if (options.users.length > 1) {
    title += 'S';
  }

  await openModal.safe({
    title,
    props: { ...options },
    component: () => import('./DeleteUsers.vue'),
  });
}

/**
 * Delete Users Confirmation modal configuration options.
 */
export interface DeleteUsersConfirmationModalOptions {
  users: models.User[];
  deleteReports?: boolean;
  deleteImmediately?: boolean;
  silent?: boolean;
}

/**
 * Open Delete Users Confirmation modal.
 *
 * @returns A promise that resolves to `true` if the user elected to continue
 * with the deletion process, or `false` if they chose not to.
 */
export async function deleteUsersConfirmation(
  options: DeleteUsersConfirmationModalOptions,
) {
  let shouldProceed;

  try {
    shouldProceed = await openModal({
      props: { ...options },
      component: () => import('./DeleteUsersConfirmation.vue'),
    });
  } catch {
    shouldProceed = false;
  }

  return shouldProceed;
}

/**
 * External link notice modal configuration options.
 */
export interface ExternalLinkNoticeModalOptions {
  link: string;
}

/**
 * Open External Link Notice modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function externalLinkNotice(
  options: ExternalLinkNoticeModalOptions,
) {
  await openModal.safe({
    title: 'EXTERNAL LINK NOTICE',
    props: { ...options },
    component: () => import('./ExternalLinkNotice.vue'),
  });
}

/**
 * Yes/No modal configuration options.
 */
export interface YesNoModalOptions {
  message?: string;
}

/**
 * Open Yes/No modal.
 *
 * @param options Modal options.
 * @return Modal promise.
 */
export async function yesNo(options: YesNoModalOptions) {
  const modalOptions = {
    title: 'ARE YOU SURE?',
    props: { ...options },
    component: () => import('./YesNo.vue'),
  };

  try {
    return await openModal<boolean>(modalOptions);
  } catch {
    return false;
  }
}
